<template>
  <div class="root">
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<style>
.root {
  background-color: var(--background-color);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
</style>
<style scoped>

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
</style>
