<template>
  <div v-if="top <= 1 || top >= 100" class="card stack" />
  <div v-else>
    <Card :value="top"/>
  </div>
</template>

<script>
import '@/css/global.css'
import Card from '@/components/Card'

export default {
  name: 'Stack',
  components: { Card },
  props: {
    top: Number
  }
}
</script>

<style scoped>
.stack {
  border: var(--border-width) dotted black;
  padding: .2vmin;
}
</style>
