<template>
  <div class="player-container">
    <div class="current-player">{{ currentPlayer }}</div>
    <div class="player-header">Players</div>
    <table>
      <tr v-for="player in players" v-bind:key="player.uuid">
        <td class="player-name">{{ player.name }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
const PLAYER_NOT_THERE = '―'

export default {
  name: 'PlayerList',
  props: {
    players: Array,
    currentPlayerUuid: String
  },
  computed: {
    currentPlayer () {
      for (const player of this.players) {
        if (player.uuid === this.currentPlayerUuid) {
          return player.name
        }
      }
      return PLAYER_NOT_THERE
    }
  }
}
</script>

<style scoped>
.player-container {
  width: 10vw;
  padding: 2.5vmin;
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
}

.player-name {
  font-size: 2vmin;
}

.player-header {
  color: rgba(0, 0, 0, .6);
  margin-bottom: 0.3rem;
  font-size: 2.5vmin;
}

.current-player {
  margin: 2.5vmin 0;
  font-size: 3vmin;
  text-align: center;
}

</style>
