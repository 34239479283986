<template>
  <div class="home">
    <h1 class="game-name fly-in">
      The Game
    </h1>
    <div class="game-selection fly-in-delayed">
      <div class="cards-selector create-game">
        <button class="global-button cards-button button-selected" id="cards-input-6" data-value="6" @click="selectCard">6</button>
        <button class="global-button cards-button" id="cards-input-7" data-value="7"  @click="selectCard">7</button>
        <button class="global-button cards-button" id="cards-input-8" data-value="8"  @click="selectCard">8</button>
      </div>
      <div class="cards-selector-descriptor create-game">
        Cards per Player
      </div>
      <div class="create-button-wrapper create-game">
        <button class="global-button " @click="onCreateClicked">Create a Game</button>
      </div>
      <div class="uuid-input-wrapper join-game">
        <input class="uuid-input" v-model="uuid" @keyup.enter="onJoinClicked" placeholder="Game ID"/>
      </div>
      <div class="join-button-wrapper join-game">
        <button class="global-button" :disabled="!joinEnabled" @click="onJoinClicked">Join a Game</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  components: {},
  data () {
    return {
      uuid: '',
      cardsPerPlayer: 6
    }
  },
  computed: {
    joinEnabled () {
      return this.isValidUuid(this.uuid)
    }
  },
  methods: {
    unselectCardButton (i) {
      const element = document.getElementById(`cards-input-${i}`)
      element.classList.remove('button-selected')
    },
    selectCardButton (i) {
      const element = document.getElementById(`cards-input-${i}`)
      element.classList.add('button-selected')
    },
    selectCard (e) {
      for (let i = 6; i < 9; i++) {
        this.unselectCardButton(i)
      }
      this.cardsPerPlayer = parseInt(e.target.getAttribute('data-value'))
      console.log(this.cardsPerPlayer)
      this.selectCardButton(this.cardsPerPlayer)
    },
    isValidUuid (uuid) {
      const re = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-5][0-9a-fA-F]{3}-[089abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/
      return re.test(uuid)
    },
    async onCreateClicked () {
      const game = await axios.post('/api/1/game', {
        cardsPerPlayer: this.cardsPerPlayer
      })
      this.openGame(game.data.uuid)
    },
    onJoinClicked () {
      if (this.joinEnabled) {
        this.openGame(this.uuid)
      }
    },
    openGame (uuid) {
      if (this.isValidUuid(uuid)) {
        this.$router.push(`/game/${uuid}`)
      }
    }
  }
}
</script>

<style scoped>
.home {
  margin: 10vh;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

:root {
  --fly-in-duration: 1.5s;
  --fly-in-delay: calc(1.5s / 2);
}

.game-selection {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: min-content min-content 4rem;
  grid-column-gap: 2.5vmin;
  box-shadow: var(--box-shadow);
  border-radius: var(--input-border-radius);
  padding: 5vmin;
}

.uuid-input {
  width: 100%;
}

.uuid-input-wrapper {
  grid-column: 1;
  grid-row: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards-selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
  grid-column: 1;
  grid-row: 1;
}

.cards-selector>* {
  margin: 0 .5rem;
}

.cards-selector-descriptor {
  color: rgba(0, 0, 0, .6);
  margin: 1rem;
  grid-column: 1;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.button-selected {
  background-color: var(--selected-color);
}

.cards-button {
  width: 3rem;
}

.create-button-wrapper {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.join-button-wrapper {
  grid-column: 2;
  grid-row: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-name {
  position: relative;
  bottom: 10vh;
  margin: 0;
  font-size: 4rem;
  text-align: center;
}

@media only screen and (max-width: 512px){
  .game-selection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .join-game {
    margin-top: 1rem;
  }

  .uuid-input-wrapper {
    margin-top: 13vmin;
  }
}

</style>
