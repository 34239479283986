<template>
  <div :class="{card: true, 'play-card': true, 'ancient-color': true,  'no-select': true, 'disabled': disabled}">
    {{ value }}
  </div>
</template>

<script>
import '@/css/global.css'

export default {
  name: 'Card',
  props: {
    value: Number,
    disabled: Boolean
  }
}
</script>

<style scoped>
.play-card {
  box-shadow: var(--box-shadow);
  font-size: 5vmin;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
