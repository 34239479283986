<template>
  <div class="card-region">
    <div class="drop-wrapper">
      <div class="card-row overlayed">
        <Stack class="card-element" v-for="top in stackTops" v-bind:key="top" :top="top"/>
      </div>
      <div class="card-row overlayed">
        <draggable id="0" class="card card-drop-wrapper" v-model="stacks[0]"
                   :group="{ name: 'cards', pull: false, put: true }"
                   item-key="id"
                   :sort="false">
          <template #item="{element}">
            <Card class="card-element" :value="element"/>
          </template>
        </draggable>
        <draggable id="1" class="card card-drop-wrapper" v-model="stacks[1]"
                   :group="{ name: 'cards', pull: false, put: true }"
                   item-key="id"
                   :sort="false">
          <template #item="{element}">
            <Card class="card-element" :value="element"/>
          </template>
        </draggable>
        <draggable id="2" class="card card-drop-wrapper" v-model="stacks[2]"
                   :group="{ name: 'cards', pull: false, put: true }"
                   item-key="id"
                   :sort="false">
          <template #item="{element}">
            <Card class="card-element" :value="element"/>
          </template>
        </draggable>
        <draggable id="3" class="card card-drop-wrapper" v-model="stacks[3]"
                   :group="{ name: 'cards', pull: false, put: true }"
                   item-key="id"
                   :sort="false">
          <template #item="{element}">
            <Card class="card-element" :value="element"/>
          </template>
        </draggable>
      </div>
    </div>
    <div>
      <draggable class="card-row" v-model="cards" :group="{ name: 'cards', pull: true, put: false }"
                 @end="dragEnded" @start="dragStarted" :move="handleMove" item-key="id" :disabled="notMyTurn">
        <template #item="{element}">
          <Card class="card-element" :value="element" :disabled="notMyTurn"/>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import Stack from '@/components/Stack'
import Card from '@/components/Card'
import draggable from 'vuedraggable'

export default {
  name: 'CardField',
  components: { draggable, Card, Stack },
  props: {
    isMyTurn: Boolean,
    stackTops: Array,
    ownedCards: Array
  },
  data () {
    return {
      stacks: [[], [], [], []],
      cards: []
    }
  },
  watch: {
    ownedCards (newValue) {
      this.cards = Object.assign([], newValue)
    }
  },
  computed: {
    notMyTurn () {
      return !this.isMyTurn
    }
  },
  methods: {
    dragStarted (e) {
      this.movedCard = this.cards[e.oldIndex]
    },
    dragEnded (e) {
      if (e.from !== e.to) {
        const stackIndex = e.to.id
        if (this.canMove(this.movedCard, stackIndex)) {
          this.stacks[stackIndex] = []
          this.$emit('moved', {
            dst: 'stack',
            card: this.movedCard,
            dstIndex: stackIndex
          })
        }
      } else {
        this.$emit('moved', {
          dst: 'cards',
          card: this.movedCard,
          dstIndex: 0
        })
      }
      this.movedCard = null
      return true
    },
    handleMove (e) {
      if (e.from === e.to) {
        return true
      }

      if (!this.isMyTurn) {
        return false
      }
      const stackIndex = e.to.id

      return this.canMove(this.movedCard, stackIndex)
    },
    canMove (card, stackIndex) {
      if (stackIndex < 0 || stackIndex >= 4) {
        return false
      }
      if (stackIndex < 2) {
        return this.stackTops[stackIndex] <= card || this.stackTops[stackIndex] - 10 === card
      } else {
        return this.stackTops[stackIndex] >= card || this.stackTops[stackIndex] + 10 === card
      }
    }
  }
}
</script>

<style scoped>

.overlayed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.card-region {
  width: 100%;
}

</style>
