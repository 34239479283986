<template>
  <div class="finished-field">
    <h1 class="fly-in">{{ message }}</h1>
    <button @click="requestNewGame" class="global-button fly-in-delayed">New Game</button>
  </div>
</template>

<script>
export default {
  name: 'FinishField',
  props: {
    remainingCards: Number
  },
  computed: {
    message () {
      if (this.remainingCards > 0) {
        return `You have lost! ${this.remainingCards} remaining cards!`
      } else {
        return 'You have won!'
      }
    }
  },
  methods: {
    requestNewGame () {
      this.$emit('on-new-game')
    }
  }
}
</script>

<style scoped>
.finished-field {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
