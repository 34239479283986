<template>
  <div class="name-field">
    <input class="name-input fly-in" v-model="name" placeholder="Name" @keyup.enter="onButtonClick"/>
    <div class="fly-in-delayed">
      <button class="global-button" :disabled="!enabled" @click="onButtonClick">Join</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NameField',
  data () {
    return {
      name: null
    }
  },
  computed: {
    enabled () {
      return this.name && this.name.trim() !== ''
    }
  },
  methods: {
    onButtonClick () {
      console.log('Button clicked')
      if (this.enabled) {
        this.$emit('entered', this.name)
      }
    }
  }
}
</script>

<style scoped>
.name-field {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name-input {
  margin: 1rem;
}
</style>
