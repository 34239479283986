<template>
  <div class="card stack-card">
    <div class="wrapper">
      <div class="stack-number">{{ topNumber }}</div>
      <div class="arrow" v-bind:class="cssClass"/>
      <div class="stack-number">{{ bottomNumber }}</div>
    </div>
  </div>
</template>

<script>
import '@/css/global.css'

export default {
  name: 'StackCard',
  props: {
    index: Number
  },
  computed: {
    cssClass () {
      if (this.index < 2) {
        return 'up'
      } else {
        return 'down'
      }
    },
    topNumber () {
      if (this.index < 2) {
        return 99
      } else {
        return 100
      }
    },
    bottomNumber () {
      if (this.index < 2) {
        return 1
      } else {
        return 2
      }
    }
  }
}
</script>

<style scoped>
.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.stack-card {
  box-shadow: var(--box-shadow);
}

.stack-number {
  font-size: 3vmin;
  margin: 1vmin;
}

.arrow {
  display: inline-block;
  box-sizing: border-box;
  height: 2vmin;
  width: 2vmin;
  border-right: .3vmin solid black;
  border-bottom: .3vmin solid black;
}

.up {
  transform: translateY(.5vmin) rotate(225deg) ;
  -webkit-transform: translateY(.5vmin) rotate(225deg) ;
}

.down {
  transform: translateY(-.5vmin) rotate(45deg);
  -webkit-transform: translateY(-.5vmin) rotate(45deg);
}
</style>
