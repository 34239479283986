<template>
  <div v-if="gameNotFound" class="game-start-error">Game not found!</div>
  <div v-else-if="gameAlreadyStarted" class="game-start-error">Game already started!</div>
  <div v-else class="game">
    <NameField v-if="showNameField" @entered="createPlayer"/>
    <div v-if="showCardField" class="card-field">
      <div class="card-row fly-in">
        <StackCard class="card-element" v-bind:key="index" v-for="index in stackIndices" :index="index"/>
      </div>
      <CardField class="fly-in" :owned-cards="cards" :stack-tops="game.stacks" :is-my-turn="isMyTurn"
                 @moved="onCardMoved"/>
      <div class="finish-button fly-in">
        <button class="global-button" @click="finishMove" :disabled="moveNotFinished">Finish Move</button>
      </div>
      <div class="game-information-item draw-stack place-left fly-in-delayed">
        <Card :value="game.drawStackSize"/>
        <button v-on:click="revert" :disabled="!canRevert" class="revert-button"><img class="revert-button-image" src="@/assets/undo.svg"
                                                                                      alt="Revert">
        </button>
      </div>
      <div :class="{'toast-placer': true, 'toast-in': !!toastMessage, 'toast-out': !toastMessage}" v-if="toastMessage">
        <div class="toast">{{toastMessage}}</div>
      </div>
      <PlayerList :players="game.players" :current-player-uuid="currentPlayerUuid"
                  class="game-information-item place-right fly-in-delayed"/>
      <div class="fly-in">
        <button @click="share" :disabled="gameStarted" class="global-button share-button">
          <img class="share-button-image" src="@/assets/share.svg" alt="Share">
          Share the game
        </button>
      </div>
    </div>
    <FinishField v-if="isFinished" :remaining-cards="game.totalRemainingCards" @on-new-game="requestNewGame"/>
  </div>
</template>

<script>
import StackCard from '../components/StackCard'
import Card from '@/components/Card'
import { GameState } from '@/GameState'
import { sleep } from '@/helper'
import axios from 'axios'
import CardField from '@/components/CardField'
import NameField from '@/components/NameField'
import FinishField from '@/components/FinishField'
import PlayerList from '@/components/PlayerOverview'

export default {
  components: { PlayerList, FinishField, NameField, CardField, StackCard, Card },
  name: 'Game',
  props: {
    uuid: String
  },
  created () {
    (async () => {
      try {
        const game = await this.loadGameState()
        await this.updateGameState(game)
        if (game.state !== GameState.NOT_STARTED && !game.players.map(p => p.uuid).includes(this.playerUuid)) {
          this.gameAlreadyStarted = true
          return
        }

        await this.updatePlayer()
        this.connectWS()
      } catch (e) {
        console.error(e)
        this.gameNotFound = true
      }
    })()
  },
  methods: {
    canFinishMove () {
      return this.isMyTurn && this.hasDroppedEnough()
    },
    revert () {
      if (this.isMyTurn && this.me?.cards?.length > 0 && this.me?.drops > 0) {
        this.ws.send(JSON.stringify({
          move: 'MOVE_REVERT',
          game: this.game.uuid,
          player: this.playerUuid,
          payload: null
        }))
      }
    },
    hasDroppedEnough () {
      return (this.game.cardsPerPlayer - this.me?.cards?.length) >= this.minDrop()
    },
    onCardMoved (e) {
      if (e.dst === 'stack') {
        this.game.stacks[e.dstIndex] = e.card
        this.pushCardDropped(e.card, e.dstIndex)
      }
    },
    minDrop () {
      if (this.game.drawStackSize === 0) {
        return 1
      } else {
        return 2
      }
    },
    finishMove () {
      if (this.canFinishMove()) {
        this.ws.send(JSON.stringify({
          move: 'MOVE_FINISH',
          game: this.game.uuid,
          player: this.playerUuid,
          payload: null
        }))
      }
    },
    pushCardDropped (card, stackIndex) {
      const json = JSON.stringify({
        move: 'MOVE_DROP',
        game: this.game.uuid,
        player: this.playerUuid,
        payload: {
          card: card,
          stack: parseInt(stackIndex)
        }
      })

      // console.log(json)

      this.ws.send(json)
    },
    async requestNewGame () {
      if (this.isFinished) {
        await axios.post(`/api/1/game/${this.uuid}/reset`)
      }
    },
    async loadGameState () {
      const gameResponse = await axios.get(`/api/1/game/${this.uuid}`)
      return gameResponse.data
    },
    async updateGameState (newState) {
      this.game = newState
      this.stacks = newState.stacks
      console.log(this.game)
      await this.updatePlayer()
    },
    async createPlayer (name) {
      const playerResponse = await axios.post(`/api/1/game/${this.uuid}/player/${name}`)
      this.name = name
      this.me = playerResponse.data
      this.setPlayerUuid(this.me.uuid)
      await this.updatePlayer()
      this.connectWS()
      const game = await this.loadGameState()
      await this.updateGameState(game)
    },
    async updatePlayer () {
      if (this.playerUuid) {
        try {
          const playerResponse = await axios.get(`/api/1/game/${this.uuid}/player/${this.playerUuid}`).catch()
          this.me = playerResponse.data
          this.cards = this.me.cards
        } catch (e) {
          console.log(e)
          this.clearPlayerUuid()
        }
      }
    },
    setPlayerUuid (uuid) {
      localStorage.setItem(this.uuid, uuid)
    },
    clearPlayerUuid () {
      // Workaround start: rerendering is triggered (this.$forceRerender() didn't work)
      this.me = 'null'
      this.me = null
      // Workaround end
      localStorage.removeItem(this.uuid)
    },
    connectWS () {
      if (this.playerUuid && (!this.ws || this.ws.readyState === WebSocket.CLOSED)) {
        try {
          const protocol = process.env.NODE_ENV === 'development' ? 'ws' : 'wss'
          const url = `${protocol}://${window.location.host}/api/1/ws/${this.game.uuid}/player/${this.playerUuid}`
          console.log(url)
          this.ws = new WebSocket(url)
          this.ws.onmessage = (message) => {
            this.updateGameState(JSON.parse(message.data))
          }
          this.ws.onopen = () => {
            console.log('Websocket opened')
          }
          this.ws.onclose = (ev) => {
            console.log('Websocket closed')
            console.log(ev)
            this.reconnect()
          }
          this.ws.onerror = (ev) => {
            console.log('Websocket error')
            console.log(ev)
          }
        } catch (e) {
          console.error(e)
          this.reconnect()
        }
      }
    },
    reconnect () {
      sleep(2000).then(() => this.connectWS())
    },
    async share () {
      if (navigator.share) {
        try {
          await navigator.share({
            url: window.location
          })
          console.log('Share dialog opened')
          return
        } catch (e) {
          console.error(e)
        }
      }
      const toastDisplayTime = 2000
      console.log('Web Share API')
      try {
        await navigator.clipboard.writeText(window.location)
        this.showToast('Game link copied', toastDisplayTime)
      } catch (e) {
        console.error(e)
        this.showToast('Could not copy game link', toastDisplayTime)
      }
    },
    showToast (message, timeout) {
      this.toastMessage = message
      setTimeout(() => {
        this.toastMessage = null
      }, timeout)
    }
  },
  computed: {
    moveNotFinished () {
      return !this.canFinishMove()
    },
    canRevert () {
      return this.me?.drops > 0
    },
    isMyTurn () {
      return !this.game.currentPlayer || this.playerUuid === this.game.currentPlayer.uuid
    },
    showCardField () {
      return this.game && (this.game.state === GameState.NOT_STARTED || this.game.state === GameState.IN_GAME) && this.hasPlayer
    },
    hasPlayer () {
      return this.game && (this.me || this.playerUuid)
    },
    showNameField () {
      return this.game && !this.hasPlayer
    },
    isFinished () {
      return this.game && (this.game.state === GameState.WON || this.game.state === GameState.LOST)
    },
    playerUuid () {
      if (this.me) {
        return this.me.uuid
      } else {
        return localStorage.getItem(this.uuid)
      }
    },
    currentPlayerUuid () {
      if (this.game && this.game.currentPlayer) {
        return this.game.currentPlayer.uuid
      }
      return null
    },
    gameStarted () {
      return this.game.state === GameState.IN_GAME
    }
  },
  data () {
    return {
      game: null,
      me: null,
      stackIndices: [0, 1, 2, 3],
      cards: [],
      stacks: [0, 0, 100, 100],
      name: null,
      gameNotFound: false,
      gameAlreadyStarted: false,
      toastMessage: null
    }
  }
}
</script>

<style scoped>
.game {
  width: 100%;
  position: relative;
}

.place-left {
  left: 0;
}

.place-right {
  right: 0;
}

.game-information-item {
  position: absolute;
  margin: 7.5vmin;
  top: 0;
}

.draw-stack {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: min-content;
}

.card-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.game-start-error {
  display: flex;
  font-size: 7vmin;
  justify-content: center;
  align-items: center;
}

.revert-button {
  width: calc(40% + 1rem);
  border: none;
  background: none;
  margin: 15px;
}

.revert-button:hover {
  cursor: pointer;
}

.revert-button:disabled {
  cursor: default;
  opacity: 0.3;
}

.revert-button-image {
  width: 100%;
}

.finish-button {
  margin: 1.5rem;
}

.share-button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.share-button-image {
  height: 75%;
}

.toast-placer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: min-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.toast {
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
  background-color: var(--ancient-color);
  padding: .75rem;
  height: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, .75);
}

@keyframes toast-animation-in-keyframes {
  from {
    bottom: -5rem;
  }
  to {
    bottom: 0;
  }
}

.toast-in {
  animation: toast-animation-in-keyframes;
  animation-duration: .3s;
}
</style>
